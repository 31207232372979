<template>
  <div class="inquire-account">

    <div class="admlog">
      <div class="box">
        <div class='tit'>
          <div class="left_zhu">
            <el-date-picker @change="rqi_huanle" v-model="value1" type="daterange" value-format="yyyy-MM-dd"
              :clearable='false' range-separator="至"  :start-placeholder="value1[0]" :end-placeholder="value1[1]">
            </el-date-picker>
          </div>
          <div class="right_zhu">
            <p>
              <span>操作对象</span>
              <el-select v-model="caozuo_duixiang" @change="caozuo_duixiang_huanle">
                <el-option v-for="(i,index) in caozuo_list" :key="index" :label="i.label" :value="i.value">
                </el-option>
              </el-select>
            </p>
            <p>
              <span>管理员</span>
              <el-select v-model="admin_renyuan" @change="ganliyuan_huanle">
                <el-option v-for="(i,index) in renyuan_list" :key="index" :label="i.user_name" :value="i.user_id">
                </el-option>
              </el-select>
            </p>
          </div>
        </div>
        <div class="tit2">
          <p>时间</p>
          <p>管理员</p>
          <p>操作对象</p>
          <p style="border:none;">操作详情</p>
        </div>
        <div class="tit3" v-if="rzhi_list.length==0">
          <p style="width:100%;">暂无数据</p>
        </div>
        <div v-if="rzhi_list.length!=0">
          <div class="tit3" v-for="(i,index) in rzhi_list" :key="index">
            <p>{{i.operate_time}}</p>
            <p>{{i.user_name}}</p>
            <p v-if="i.aim == 4">人员</p>
            <p v-if="i.aim == 1">菜品</p>
            <p style="border:none; color: #fc4353;" v-if="i.type == 0">错误：{{i.remark}}</p>
            <p style="border:none; " v-if="i.type == 1">{{i.number}} 行</p>
          </div>
        </div>
      </div>
    </div>
    <el-pagination layout="prev, pager, next" :total="tableal" @current-change="handleCurrentChange" :page-size='10'>
    </el-pagination>
  </div>
</template>

<script>
  import {
    query_ent_sub_manager_list,
    query_ent_primary_manager_detail,
    get_system_logs_list,
    query_import_log_list,
    query_import_log_user_list
  } from '@api/pulic'
  export default {
    data() {
      return {
        ent_id: '',
        value1: [],
        formInline: {
          user: '',
          region: ''
        },
        start_sj: '',
        end_sj: '',
        caozuo_duixiang: '全部',
        admin_renyuan: '',
        tableal: 1,
        caozuo_list: [{
            value: null,
            label: '全部'
          },
          {
            value: '1',
            label: '菜品'
          },
          {
            value: '4',
            label: '人员'
          },
          // {
          //   value: '3',
          //   label: '收银'
          // },
          // {
          //   value: '4',
          //   label: '烹饪'
          // },
          // {
          //   value: '5',
          //   label: '备餐'
          // },
          // {
          //   value: '6',
          //   label: '采购'
          // },
          // {
          //   value: '7',
          //   label: '供应商'
          // },
          // {
          //   value: '8',
          //   label: '库房'
          // },
          // {
          //   value: '9',
          //   label: '激励币'
          // },
          // {
          //   value: '10',
          //   label: '人力'
          // },
          // {
          //   value: '11',
          //   label: '会员卡'
          // },
        ],
        renyuan_list: [],

        // {
        //   value: '0',
        //   label: '全部',
        //   id: null
        // }
        rzhi_list: [],
        caozuo_id: null,
        guanli_id: null,

        aimS: null,
        tiem_Medium: '',
 
      }
    },
    mounted() {
      // this.jinyue()
      this.jichu()

      
      

      var aData = new Date();
      // 获取当前月日
      var minute = aData.getMonth() + 1;
      var second = aData.getDate();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      this.tiem_Medium = aData.getFullYear() + "-" + minute + "-" + second// 获取当前日期
 
      console.log(this.tiem_Medium)

    
 
      this.value1[0] =  this.tiem_Medium
      this.value1[1] =  this.tiem_Medium

      this.importList()
    },
    created() {
      this.jichu()
    },
    methods: {
      // 数据渲染
      importList() {
        query_import_log_list({
          data: {
            ent_id: this.$store.state.ent_id,
            aim: this.aimS, // 对象
            type: null,
            date_start: this.value1[0], //数据开始
            date_end: this.value1[1], // 数据结束
            page_size: '10', // 分页参数
            page_index: '1', // 分页下标
            user_id: this.admin_renyuan ? this.admin_renyuan : null
          }
        }).then(res => {
          this.rzhi_list = res.body.data.list
          this.tableal = res.body.data.total
          console.log(res)
        })
      },
      // 基础
      jichu() {
        // query_ent_primary_manager_detail({
        //   data: {
        //     ent_id: this.$store.state.ent_id,
        //   }
        // }).then(res => {
        //   this.renyuan_list = res.body.data.date_list
        // })

        query_import_log_user_list({
          data: {
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {
          console.log(res,'ssssssssssssssssssssssssssssssss')
          this.renyuan_list = res.body.data
          console.log(res.body.data)
          console.log(this.renyuan_list)
        })
      },
      // 时间小于10前面加0
      pa(s) {
        return s < 10 ? '0' + s : s
      },
      // 获取本月时间
      jinyue() {
        get_system_logs_list({
          data: {
            ent_id: this.$store.state.ent_id,
            date_begin: this.value1[0],
            date_end: this.value1[1],
            operate_aim: this.caozuo_id,
            user_id: this.guanli_id,
            page_index: '1',
            page_size: '10'
          }
        }).then(res => {
            this.rzhi_list=res.body.data.list
            this.tableal = res.body.data.total
            console.log(this.rzhi_list)
        })
      },

      handleCurrentChange(val){
        this.val = val
        query_import_log_list({
          data: {
            ent_id: this.$store.state.ent_id,
            date_begin: this.value1[0],
            date_end: this.value1[1],
            operate_aim: this.caozuo_id,
            user_id: this.guanli_id,
            page_index: '1',
            page_size: '10'
          }
        }).then(res => {
          this.rzhi_list = res.body.data.list
          this.tableal = res.body.data.total
        })
      },

      // 操作对象
      caozuo_duixiang_huanle(e) {
        this.aimS = e
        this.rqi_huanle()
      },

      // 管理人员
      ganliyuan_huanle(e) {
        console.log(this.admin_renyuan)
        console.log(e)
        this.rqi_huanle()
      },
      // 年月日
      rqi_huanle() {
        this.importList()
        console.log(this.value1)
      },


      journal() {
        this.$router.push('/journal')
      }
    }
  }

</script>

<style scoed lang="scss">
  .el-main {

    background: #f6f6fa;
  }


  .inquire-account {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 44px 0 100px 0;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
    border-radius: 15px;
    margin: 30px 0;

    .inquire-box {
      padding: 0 0 0 40px;

      & div {
        margin-bottom: 42px;
      }

      & div>.el-button--danger:first-of-type {
        margin-right: 60px;
      }

      & div>.el-button--danger:last-of-type {
        background: #fff;
        color: #fc4452;
        padding: 14px 26px;

        &:hover {
          color: #fc4452;
        }
      }
    }
  }

  .admlog {
    height: 100%;
    width: 100%;

    .box {
      padding: 0 30px 40px 30px;

      .tit {
        display: flex;
        flex-wrap: wrap;

        .left_zhu {
          font-size: 14px;
          color: #4C4A4D;
          display: flex;
          align-items: center;
          margin-right: 30px;
        }

        .right_zhu {
          display: flex;
          align-items: center;

          p {
            margin-right: 20px;

            span {
              margin-right: 10px;
            }

            display: flex;
            align-items: center;
            font-size: 14px;
            color:#4C4A4D;
            display: flex;
            align-items: center;
            width: 300px;
          }
        }
      }

      .tit2 {
        height: 50px;
        background: #E7E8EB;
        margin-top: 40px;
        display: flex;
        align-items: center;

        p {
          &:first-of-type {
            width: 20%;
          }

          &:nth-of-type(2) {
            width: 20%;
          }

          &:nth-of-type(3) {
            width: 25%;
          }

          &:last-of-type {
            width: 35%;
          }

          font-size: 16px;
          color:#1A2533;
          padding: 0 55px;
          min-height:30px;
          line-height: 30px;
          text-align: center;
          border-right: 1px solid #979797;
        }
      }

      .tit3 {
        min-height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EEEEEE;
        cursor: pointer;

        &:hover {
          background: rgb(250, 249, 249);
        }

        p {
          &:first-of-type {
            width: 20%;
          }

          &:nth-of-type(2) {
            width: 20%;
          }

          &:nth-of-type(3) {
            width: 25%;
          }

          &:last-of-type {
            width: 35%;
          }

          font-size: 14px;
          color:#1A2533;
          width: 180px;
          text-align: center;
          min-height:30px;
          line-height: 30px;
        }
      }
    }
  }

</style>
